import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import authApplication from "./views/auth/authenticated-mfe-layout.html";
import { removeTokens, verifyToken } from "./utils/auth.utils";
import Cookies from "universal-cookie";
import styles from "./styles/main.html";

const cookie = new Cookies();

document.head.innerHTML += styles;

const routes = constructRoutes(authApplication);
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach((app) => {
  registerApplication({
    name: app.name,
    app: app.app,
    activeWhen: () => true,
  });
});

window.addEventListener("single-spa:routing-event", async (evt) => {
  const validToken = await verifyToken();

  if (!validToken) {
    removeTokens();
    window.location.replace("/login");
  }
});

layoutEngine.activate();
start();
