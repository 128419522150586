const { APP_NAME, API_URL, APOLLO_GW, ENV } = process.env;

const environment = {
  ENV: ENV ?? "local",
  APP_NAME: APP_NAME ?? "cto-bo-root",
  API_URL: API_URL ?? "/api/auth",
  APOLLO_GW: APOLLO_GW ?? "/api/gw",
};

export default environment;
