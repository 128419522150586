import axios from "axios";
import jwtDecode from "jwt-decode";
import env from "../environment";
import Cookie from "universal-cookie";

const cookie = new Cookie();

export const verifyToken = async () => {
  const token = cookie.get("access_token");

  if (!token) return false;

  const decoded: any = jwtDecode(token);
  if (!decoded || !decoded.hasOwnProperty("exp")) return false;

  if (decoded.exp > Date.now() / 1000) {
    return await validateToken(token);
  } else {
    return refreshToken();
  }
};

const validateToken = async (token: string) => {
  try {
    const res = await axios.post(`${env.API_URL}/v1/authenticate/valid/token`, {
      token,
    });
    if (res.data) {
      if (res.data.valid) {
        return refreshToken();
      }
      return res.data.valid;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const refreshToken = async () => {
  try {
    const refreshToken = cookie.get("refresh_token");
    const decoded: any = jwtDecode(cookie.get("access_token"));

    const refreshedToken = await axios.get(
      `${env.API_URL}/v1/authenticate/refresh/${refreshToken}/${decoded?.user_name}`,
    );

    if (refreshedToken.data.jwt && refreshedToken.data.refresh) {
      cookie.set("access_token", refreshedToken.data.jwt);
      cookie.set("refresh_token", refreshedToken.data.refresh);
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const removeTokens = () => {
  cookie.remove("access_token");
  cookie.remove("refresh_token");
};
